var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"features-tiles section center-content",class:[
    _vm.topOuterDivider && 'has-top-divider',
    _vm.bottomOuterDivider && 'has-bottom-divider',
    _vm.hasBgColor && 'has-bg-color',
    _vm.invertColor && 'invert-color'
  ]},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"features-tiles-inner section-inner",class:[
        _vm.topDivider && 'has-top-divider',
        _vm.bottomDivider && 'has-bottom-divider'
      ]},[_c('c-section-header',{staticClass:"center-content",attrs:{"data":_vm.sectionHeader}}),_c('div',{staticClass:"tiles-wrap",class:[_vm.pushLeft && 'push-left']},_vm._l((_vm.items),function(item){return _c('div',{key:item.id,staticClass:"tiles-item reveal-from-bottom",attrs:{"data-reveal-container":".tiles-wrap","data-reveal-delay":100 * (item.id - 1)}},[_c('div',{staticClass:"tiles-item-inner"},[_c('div',{staticClass:"features-tiles-item-header"},[_c('div',{staticClass:"features-tiles-item-image mb-16"},[_c('mdicon',{attrs:{"name":item.icon,"size":60}})],1)]),_c('div',{staticClass:"features-tiles-item-content"},[_c('h4',{staticClass:"mt-0 mb-8"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"m-0 text-sm"},[_vm._v(" "+_vm._s(item.text)+" ")])])])])}),0)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }