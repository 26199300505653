var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"features-split section",class:[
    _vm.topOuterDivider && 'has-top-divider',
    _vm.bottomOuterDivider && 'has-bottom-divider',
    _vm.hasBgColor && 'has-bg-color',
    _vm.invertColor && 'invert-color'
  ]},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"features-split-inner section-inner",class:[
        _vm.topDivider && 'has-top-divider',
        _vm.bottomDivider && 'has-bottom-divider'
      ]},[_c('c-section-header',{staticClass:"center-content",attrs:{"data":_vm.sectionHeader}}),_c('div',{staticClass:"split-wrap",class:[
          _vm.invertMobile && 'invert-mobile',
          _vm.invertDesktop && 'invert-desktop',
          _vm.alignTop && 'align-top'
        ]},_vm._l((_vm.items),function(item){return _c('div',{key:item.id,staticClass:"split-item"},[_c('div',{staticClass:"split-item-content center-content-mobile"},[_c('h3',{class:("mt-0 mb-16 reveal-from-" + (item.id % 2 == 1 ? 'left' : 'right')),attrs:{"data-reveal-container":".split-item"}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{class:("m-0 reveal-from-" + (item.id % 2 == 1 ? 'left' : 'right')),attrs:{"data-reveal-delay":"100","data-reveal-container":".split-item"}},[_vm._v(" "+_vm._s(item.text)+" ")]),(item.link)?_c('p',{class:("m-0 reveal-from-" + (item.id % 2 == 1 ? 'left' : 'right')),attrs:{"data-reveal-delay":"100","data-reveal-container":".split-item"}},[_c('a',{attrs:{"href":item.link}},[_vm._v(_vm._s(item.link.substring(8)))])]):_vm._e()]),_c('div',{staticClass:"reveal-from-bottom",staticStyle:{"margin":"auto"},attrs:{"data-reveal-container":".split-item","data-reveal-delay":"200"}},[_c('a',{attrs:{"href":item.link}},[_c('c-image',{attrs:{"src":require(("@/assets/images/" + (item.image.name))),"width":item.image.width}})],1)])])}),0)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }