var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"clients section reveal-fade",class:[
    _vm.topOuterDivider && 'has-top-divider',
    _vm.bottomOuterDivider && 'has-bottom-divider',
    _vm.hasBgColor && 'has-bg-color',
    _vm.invertColor && 'invert-color'
  ]},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"clients-inner section-inner",class:[
        _vm.topDivider && 'has-top-divider',
        _vm.bottomDivider && 'has-bottom-divider'
      ]},[_c('ul',{staticClass:"list-reset"},[_c('li',{staticClass:"reveal-from-bottom"},[_c('c-image',{attrs:{"src":require('@/assets/images/clients.svg'),"width":130}})],1),_c('li',{staticClass:"reveal-from-bottom"},[_c('c-image',{attrs:{"src":require('@/assets/images/client-evo.svg'),"alt":"EVO","width":70}})],1),_c('li',{staticClass:"reveal-from-bottom"},[_c('c-image',{attrs:{"src":require('@/assets/images/client-datacap.svg'),"alt":"datacap","width":160}})],1),_c('li',{staticClass:"reveal-from-bottom"},[_c('c-image',{attrs:{"src":require('@/assets/images/client-touchsuite.svg'),"alt":"TouchSuite","width":150}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }