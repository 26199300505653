<template>
  <section
    style="overflow: hidden"
    class="hero section center-content"
    :class="[
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color'
    ]"
  >
    <div class="container-sm">
      <div
        class="hero-inner section-inner pb-0"
        :class="[
          topDivider && 'has-top-divider',
          bottomDivider && 'has-bottom-divider'
        ]"
      >
        <div class="hero-content">
          <h1 class="mt-0 mb-12 reveal-from-top" data-reveal-delay="150">
            Experts in Connected Commerce
          </h1>
          <div class="container-xs">
            <p class="m-0 mb-32 reveal-from-top" data-reveal-delay="300">
              Our team of payment industry experts has a passion for building
              simple and scalable solutions
            </p>
          </div>
        </div>
        <div class="hero-figure reveal-from-bottom" data-reveal-delay="600">
          <c-image
            class="has-shadow"
            :src="
              require('@/assets/images/pexels-christina-morillo-1181345.jpg')
            "
            alt="Hero"
            :width="896"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { SectionProps } from '@/utils/SectionProps.js'
// import CButton from '@/components/elements/Button.vue'
import CImage from '@/components/elements/Image.vue'

export default {
  name: 'CHeroFull',
  mixins: [SectionProps],
  components: {
    // CButton,
    CImage
  }
}
</script>
