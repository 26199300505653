<template>
  <section
    class="clients section reveal-fade"
    :class="[
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color'
    ]"
  >
    <div class="container">
      <div
        class="clients-inner section-inner"
        :class="[
          topDivider && 'has-top-divider',
          bottomDivider && 'has-bottom-divider'
        ]"
      >
        <ul class="list-reset">
          <li class="reveal-from-bottom">
            <c-image
              :src="require('@/assets/images/clients.svg')"
              :width="130"
            />
          </li>
          <li class="reveal-from-bottom">
            <c-image
              :src="require('@/assets/images/client-evo.svg')"
              alt="EVO"
              :width="70"
            />
          </li>
          <li class="reveal-from-bottom">
            <c-image
              :src="require('@/assets/images/client-datacap.svg')"
              alt="datacap"
              :width="160"
            />
          </li>
          <li class="reveal-from-bottom">
            <c-image
              :src="require('@/assets/images/client-touchsuite.svg')"
              alt="TouchSuite"
              :width="150"
            />
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { SectionProps } from '@/utils/SectionProps.js'
import CImage from '@/components/elements/Image.vue'

export default {
  name: 'CClients',
  components: {
    CImage
  },
  mixins: [SectionProps]
}
</script>
