var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"cta section center-content-mobile",class:[
    _vm.topOuterDivider && 'has-top-divider',
    _vm.bottomOuterDivider && 'has-bottom-divider',
    _vm.hasBgColor && 'has-bg-color',
    _vm.invertColor && 'invert-color',
    'pt-32'
  ]},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"cta-inner section-inner reveal-from-bottom",class:[
        _vm.topDivider && 'has-top-divider',
        _vm.bottomDivider && 'has-bottom-divider',
        _vm.split && 'cta-split'
      ]},[_vm._m(0),_c('div',{staticClass:"cta-action"},[_c('c-button',{attrs:{"tag":"a","color":"primary","wide-mobile":"","href":"mailto:info@galaxy-pay.com"}},[_vm._v(" Contact Us ")])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cta-slogan"},[_c('h3',{staticClass:"m-0"},[_vm._v("Want to learn more?")])])}]

export { render, staticRenderFns }