<template>
  <section
    class="features-tiles section center-content"
    :class="[
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color'
    ]"
  >
    <div class="container">
      <div
        class="features-tiles-inner section-inner"
        :class="[
          topDivider && 'has-top-divider',
          bottomDivider && 'has-bottom-divider'
        ]"
      >
        <c-section-header :data="sectionHeader" class="center-content" />
        <div class="tiles-wrap" :class="[pushLeft && 'push-left']">
          <div
            class="tiles-item reveal-from-bottom"
            data-reveal-container=".tiles-wrap"
            :data-reveal-delay="100 * (item.id - 1)"
            v-for="item in items"
            :key="item.id"
          >
            <div class="tiles-item-inner">
              <div class="features-tiles-item-header">
                <div class="features-tiles-item-image mb-16">
                  <mdicon :name="item.icon" :size="60" />
                </div>
              </div>
              <div class="features-tiles-item-content">
                <h4 class="mt-0 mb-8">{{ item.title }}</h4>
                <p class="m-0 text-sm">
                  {{ item.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { SectionTilesProps } from '@/utils/SectionProps.js'
import CSectionHeader from '@/components/sections/partials/SectionHeader.vue'

export default {
  name: 'CFeaturesTiles',
  components: {
    CSectionHeader
  },
  mixins: [SectionTilesProps],
  data() {
    return {
      sectionHeader: {
        title: 'Architecture',
        paragraph: 'These priciples guide the creation of all of our products'
      },
      items: [
        {
          id: 1,
          icon: 'database',
          title: 'Database First',
          text: 'Our years of SQL expertise help build a strong foundation for rapid application development'
        },
        {
          id: 2,
          icon: 'wrench',
          title: 'Modern Frameworks',
          text: '.NET Core for the backend and Vue.js for the frontend, we have the tools to build what you need'
        },
        {
          id: 3,
          icon: 'palette',
          title: 'Material Design',
          text: 'Material is a design system, backed by open-source code, that helps build better digital experiences'
        }
      ]
    }
  }
}
</script>
