import Vue from 'vue'

import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

import mdiVue from 'mdi-vue/v2'
import * as mdijs from '@mdi/js'
Vue.use(mdiVue, { icons: mdijs })

import App from './App.vue'
import router from './router'

import '@/assets/scss/style.scss'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
