<template>
  <section
    class="features-split section"
    :class="[
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color'
    ]"
  >
    <div class="container">
      <div
        class="features-split-inner section-inner"
        :class="[
          topDivider && 'has-top-divider',
          bottomDivider && 'has-bottom-divider'
        ]"
      >
        <c-section-header :data="sectionHeader" class="center-content" />
        <div
          class="split-wrap"
          :class="[
            invertMobile && 'invert-mobile',
            invertDesktop && 'invert-desktop',
            alignTop && 'align-top'
          ]"
        >
          <div class="split-item" v-for="item in items" :key="item.id">
            <div class="split-item-content center-content-mobile">
              <h3
                :class="`mt-0 mb-16 reveal-from-${
                  item.id % 2 == 1 ? 'left' : 'right'
                }`"
                data-reveal-container=".split-item"
              >
                {{ item.title }}
              </h3>
              <p
                :class="`m-0 reveal-from-${
                  item.id % 2 == 1 ? 'left' : 'right'
                }`"
                data-reveal-delay="100"
                data-reveal-container=".split-item"
              >
                {{ item.text }}
              </p>
              <p
                v-if="item.link"
                :class="`m-0 reveal-from-${
                  item.id % 2 == 1 ? 'left' : 'right'
                }`"
                data-reveal-delay="100"
                data-reveal-container=".split-item"
              >
                <a :href="item.link">{{ item.link.substring(8) }}</a>
              </p>
            </div>
            <div
              class="reveal-from-bottom"
              data-reveal-container=".split-item"
              data-reveal-delay="200"
              style="margin: auto"
            >
              <a :href="item.link">
                <c-image
                  :src="require(`@/assets/images/${item.image.name}`)"
                  :width="item.image.width"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { SectionSplitProps } from '@/utils/SectionProps.js'
import CSectionHeader from '@/components/sections/partials/SectionHeader.vue'
import CImage from '@/components/elements/Image.vue'

export default {
  name: 'CFeaturesSplit',
  components: {
    CSectionHeader,
    CImage
  },
  mixins: [SectionSplitProps],
  data() {
    return {
      sectionHeader: {
        title: 'Products',
        paragraph: 'We work on ideas we believe in'
      },
      items: [
        {
          id: 1,
          image: { name: 'gift-card.jpg', width: 350 },
          title: 'Stored Value (Sold)',
          text: 'An enterprise-grade gift card processing and management platform'
        },
        {
          id: 2,
          image: { name: 'keg.png', width: 140 },
          title: 'KegOrders',
          text: 'An application for breweries to accept online keg orders, and to manage the workflow of those orders in-house',
          link: 'https://kegorders.com'
        },
        {
          id: 3,
          image: { name: 'iou.png', width: 350 },
          title: 'IOU Kid!',
          text: "Keep track of your child's money with a digital ledger and automated allowance",
          link: 'https://ioukid.com'
        }
      ]
    }
  }
}
</script>
