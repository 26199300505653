<template>
  <nav class="footer-nav">
    <ul class="list-reset">
      <li>
        <a href="mailto:info@galaxy-pay.com">Contact</a>
      </li>
      <li>
        <a href="#">About us</a>
      </li>
      <li>
        <a href="mailto:support@galaxy-pay.com">Support</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'CFooterNav'
}
</script>
